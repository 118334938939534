import { Button, Input, message, Space, Table, Modal, Tag, Dropdown, Typography } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import ApplyOrderDetail from "./ApplyOrderDetail";
import moment from "moment";
import EUtils from "../../../utils/EUtils";
const { confirm } = Modal;

const ApplyOrder = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const columns: any[] = [
        {
            key: 'applyOrderNO', title: '申报单号', dataIndex: 'applyOrderNO', align: 'center'
            , fixed: 'left', ellipsis: true, width: 120,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/yearonline/applyorderdetail?oid=' + record.applyOrderID
            }}>{text}</a>
        }, {
            key: 'applyTime', title: '申报日期', dataIndex: 'applyTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                moment(text).format('YYYY-MM-DD')
            )
        }, {
            title: '申报状态', dataIndex: 'state', align: 'center', width: 80,
            render: (text: string, record: any) => {
                return <Tag color={record.stateEnum == 0 ? '' : 'green'}>{text}</Tag>;
            }
        }, {
            key: 'applyType', title: '申报类型', dataIndex: 'applyType',
            align: 'center', width: 100, ellipsis: true,
            render: (text: string, record: any) => (
                text + (record.applyTypeEnum == 0 ? '' : '(' + moment(record.requiredTime).format('YYYY-MM-DD HH:mm') + ')')
            )
        }, {
            title: '发送状态', dataIndex: 'sendState', align: 'center', width: 80,
            render: (text: string, record: any) => {
                return <Tag color={record.sendStateEnum == 0 ? '' : 'green'}>{text}</Tag>;
            }
        }, {
            key: 'sendTime', title: '发送日期', dataIndex: 'sendTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        }
        , { key: 'agreementNO', title: '协议号', dataIndex: 'agreementNO', align: 'center', width: 100, ellipsis: true },
        { key: 'partName', title: '货物名称', dataIndex: 'partName', align: 'center', ellipsis: true, width: 120 }
        , {
            key: 'carrierTime', title: '承运日期', dataIndex: 'carrierTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                text ? moment(text).format('YYYY-MM-DD') : ''
            )
        }
        , { key: 'packages', title: '货物包装', dataIndex: 'packages', align: 'center', width: 100, ellipsis: true }
        , { key: 'eaQty', title: '货物件数', dataIndex: 'eaQty', align: 'center', width: 100, ellipsis: true }
        , { key: 'startAddress', title: '始发地', dataIndex: 'startAddress', align: 'center', width: 100, ellipsis: true }
        , { key: 'destAddress', title: '目的地', dataIndex: 'destAddress', align: 'center', width: 100, ellipsis: true }
        , { key: 'truckNO', title: '车牌号', dataIndex: 'truckNO', align: 'center', width: 100, ellipsis: true }
        , { key: 'transportNO', title: '运单号', dataIndex: 'transportNO', align: 'center', width: 100, ellipsis: true }
        , {
            key: 'insureAmount', title: '保额', dataIndex: 'insureAmount', align: 'center'
            , width: 100, ellipsis: true, render: (text: string, record: any) => (
                text + '万元'
            )
        }
        , {
            key: 'applyAllocationNO', title: '保单编码', dataIndex: 'applyAllocationNO', align: 'center'
            , width: 100, ellipsis: true,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID
            }}>{text}</a>
        }
        , { title: '备注', dataIndex: 'remark', align: 'center', width: 200, ellipsis: true }
        , {
            key: 'opration', title: '操作', align: 'center', width: 150, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    <Button size="small" type="primary" onClick={() => {
                        window.location.href = '/yearonline/applyorderdetail?oid=' + record.applyOrderID
                    }}>查看</Button>
                    <Button size="small" onClick={() => {
                        window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID
                    }}>查看保单</Button>
                    {/* <Button size="small" type="primary" danger onClick={() => {
                        setEditRecord({
                            viewMode: 1,
                            applyApplyOrderID: record.applyApplyOrderID
                        });
                        setIsOpen(true)
                    }}>邮件发送</Button> */}
                </Space >
            )
        }];
    const [scrollY, setScrollY] = useState("")
    //页面加载完成后才能获取到对应的元素及其位置
    useEffect(() => {
        setScrollY(EUtils.getTableScroll(90, ''))
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            ...page
        };
        Server.Post(URL.ApplyOrder.GetApplyOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    return <div style={{ height: '100%' }}>
        <div>
            <div>
                <Input.Search allowClear style={{ width: '320px' }}
                    onChange={(e) => { setSearchValue(e.target.value) }}
                    onSearch={() => { loadData() }}
                    placeholder="输入产品名称、保险公司" />
            </div>
            <div style={{ marginTop: '5px' }}>
                <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                    <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>申报记录列表</span>
                </div>
                <Table size="small" bordered
                    scroll={{ x: 'max-content', y: scrollY }}
                    columns={columns}
                    dataSource={dataSource}
                    summary={(pageData) => {
                        var totalInsureAmount = 0;
                        pageData.forEach(({ insureAmount }) => {
                            totalInsureAmount += insureAmount;
                        });
                        return <Table.Summary fixed>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={1} align={'center'}>
                                    <Typography.Text>合计</Typography.Text></Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={14} align={'center'}></Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={1} align={'center'}>
                                    <Typography.Text>{totalInsureAmount}{'万元'}</Typography.Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </Table.Summary>
                    }}
                    pagination={{
                        position: ['bottomRight'],
                        style: { marginRight: '5px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }} />
            </div>
        </div>
    </div>
}
export default ApplyOrder;
import { Button, Input, message, Space, Table, Modal, Tag, Dropdown } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import ProductEdit from "./ApplyProductEdit";
import moment from "moment";
const { confirm } = Modal;

const Product = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const columns: any[] = [
        {
            key: 'applyProductName', title: '产品名称', dataIndex: 'applyProductName', align: 'center'
            , fixed: 'left', ellipsis: true, width: 220,
            render: (text: any, record: any) => <a onClick={() => {
                setEditRecord({
                    viewMode: 1,
                    applyProductID: record.applyProductID
                });
                setIsOpen(true)
            }}>{text}</a>
        }
        , {
            title: '启用状态', dataIndex: 'isActive', align: 'center', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
            }
        }
        , { key: 'companyName', title: '保险公司', dataIndex: 'companyName', align: 'center', width: 120, ellipsis: true }
        , { key: 'singleLimit', title: '单次限额', dataIndex: 'singleLimit', align: 'center', width: 100, ellipsis: true }
        , { key: 'deductibleAmountDesc', title: '免赔额', dataIndex: 'deductibleAmountDesc', align: 'center', width: 120, ellipsis: true }
        , { key: 'insureArea', title: '承保区域', dataIndex: 'insureArea', align: 'center', width: 200, ellipsis: true }
        , { key: 'applyEmail', title: '申报邮箱', dataIndex: 'applyEmail', align: 'center', width: 200, ellipsis: true }
        , { key: 'ccEmail', title: '抄送邮箱', dataIndex: 'ccEmail', align: 'center', width: 200, ellipsis: true }
        , { key: 'remark', title: '备注', dataIndex: 'remark', align: 'center', width: 150, ellipsis: true }
        , {
            key: 'opration', title: '操作', align: 'center', width: 150, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    <Button size="small" onClick={() => {
                        setEditRecord({
                            viewMode: 1,
                            applyProductID: record.applyProductID
                        });
                        setIsOpen(true)
                    }}>编辑</Button>
                    {!record.isActive ? <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button> : ''}
                    {!record.isActive ? <Button type='primary' onClick={() => { showActiveConfirm(record) }} size="small">启用</Button> : ''}
                    {record.isActive ? <Button type='primary' onClick={() => { showActiveConfirm(record) }} size="small" danger>禁用</Button> : ''}
                </Space >
            )
        }];

    const items = [
        { key: 0, label: '投保申明' },
        { key: 1, label: '投保须知' },
        { key: 2, label: '保险条款' },
    ];

    const onclickRow = (record: any, item: any) => {
        var type = 0;
        var title = '投保申明'
        switch (item.key) {
            case '0':
                type = 0;
                title = '投保申明'
                break;
            case '1':
                type = 1;
                title = '投保须知'
                break;
            case '2':
                type = 2;
                title = '保险条款'
                break;
        }
        setEditRecord({
            type: type,
            title: title + '[' + record.applyProductName + ']',
            applyProductID: record.applyProductID
        })
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除产品',
            icon: <ExclamationCircleFilled />,
            content: '产品名称[' + record.applyProductName + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { applyProductID: record.applyProductID }
                Server.Post(URL.ApplyProduct.DeleteApplyProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };
    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用申报产品' : '启用申报产品',
            icon: <ExclamationCircleFilled />,
            content: '申报产品[' + record.applyProductName + ']，确认要'
                + (record.isActive ? '禁用' : '启用')
                + (record.isActive ? '。禁用申报产品同时将下线已上线的申报产品,请慎重！' : ''),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    applyProductID: record.applyProductID,
                    isActive: !record.isActive
                }
                Server.Post(URL.ApplyProduct.SetActiveApplyProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.ApplyProduct.GetApplyProductList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }

    return <div style={{ height: '100%' }}>
        <div>
            <div>
                <Input.Search allowClear style={{ width: '320px' }}
                    onChange={(e) => { setSearchValue(e.target.value) }}
                    onSearch={() => { loadData() }}
                    placeholder="输入产品名称、保险公司" />
            </div>
            <div style={{ marginTop: '5px' }}>
                <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                    <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>申报产品列表</span>
                    <Space style={{ float: 'right' }}>
                        <Button type="primary" onClick={() => {
                            setEditRecord({
                                viewMode: 0,
                                vendorID: null
                            });
                            setIsOpen(true)
                        }}>新增申报产品</Button>
                    </Space>
                </div>
                <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { marginRight: '10px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
            </div>
        </div>
        <ProductEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
    </div>
}
export default Product;
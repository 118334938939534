import { Badge, Button, Input, Radio, Space, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from 'moment';
import 'moment/locale/zh-cn';

const OnlineOrder = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [state, setState] = useState<any>('-1');
    const [dataSource, setDataSource] = useState<any>([]);

    const columns: ColumnsType<any> = [
        {
            key: 'orderNO', title: '保单编码', dataIndex: 'orderNO',
            align: 'center', fixed: 'left', width: 120, ellipsis: true,
            render: (text, record) => <a onClick={() => { }}>{text}</a>,
        },
        {
            key: 'state', title: '保单状态', dataIndex: 'state',
            align: 'center', fixed: 'left', width: 120, ellipsis: true, render: (text, record) => (
                record.stateEnum == 0 ? <Badge status={'default'} text={text} /> :
                    (record.stateEnum == 1 ? <Badge status={'warning'} text={text} /> :
                        (record.stateEnum == 2 ? <Badge status={'processing'} text={text} /> :
                            (record.stateEnum == 3 ? <Badge status={'success'} text={text} /> :
                                (record.stateEnum == 4 ? <Badge status={'warning'} text={text} /> :
                                    (record.stateEnum == 5 ? <Badge status={'error'} text={text} /> :
                                        (record.stateEnum == 6 ? <Badge status={'error'} text={text} /> :
                                            (record.stateEnum == 7 ? <Badge status={'success'} text={text} /> :
                                                (record.stateEnum == 8 ? <Badge status={'success'} text={text} /> :
                                                    (record.stateEnum == 9 ? <Badge status={'default'} text={text} /> : '')))))))))
            )

        }, {
            key: 'insuranceNO', title: '保单号', dataIndex: 'insuranceNO',
            align: 'center', fixed: 'left', width: 180, ellipsis: true
        }, {
            key: 'channel', title: '保单来源', dataIndex: 'channel',
            align: 'center', width: 50, ellipsis: true
        },
        {
            key: 'insureTime', title: '投保时间', dataIndex: 'insureTime',
            align: 'center', width: 150, render: (text, record) => (
                text ? moment(text).format('YYYY-MM-DD HH:mm:ss') : ''
            )
        },
        {
            key: 'insureType', title: '投保类型', dataIndex: 'insureType',
            align: 'center', width: 100, ellipsis: true
        },
        {
            key: 'insureBy', title: '投保人/企业', dataIndex: 'insureBy',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'insuredBy', title: '被保人/企业', dataIndex: 'insuredBy',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'departureTime', title: '起运时间', dataIndex: 'departureTime',
            align: 'center', width: 150, render: (text, record) => (
                moment(text).format('YYYY-MM-DD')
            )
        },
        {
            key: 'startAddress', title: '起运地', dataIndex: 'startAddress',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'destAddress', title: '目的地', dataIndex: 'destAddress',
            align: 'center', width: 150, ellipsis: true
        }, {
            key: 'transitAddress', title: '中转地', dataIndex: 'transitAddress',
            align: 'center', width: 150, ellipsis: true
        },
        {
            key: 'insureID', title: '投保ID', dataIndex: 'insureID',
            align: 'center', width: 100, ellipsis: true
        },
        {
            key: 'insureTel', title: '投保电话', dataIndex: 'insureTel',
            align: 'center', width: 100, ellipsis: true
        },
        {
            key: 'insuredID', title: '被保ID', dataIndex: 'insuredID',
            align: 'center', width: 100, ellipsis: true
        },
        {
            key: 'insuredTel', title: '被保电话', dataIndex: 'insuredTel',
            align: 'center', width: 100, ellipsis: true
        },
        {
            key: '1', title: '公司|险种|类型', width: 200,
            align: 'center', render: (text, record) => (
                <div>
                    {record.companyName + '|' + record.productType + '|' + record.type}
                </div>
            ), ellipsis: true
        },
        {
            key: 'partValue', title: '货值', dataIndex: 'partValue', width: 100,
            align: 'center', render: (text, record) => (
                text + '万元'
            )
        },
        {
            key: 'amount', title: '保费', dataIndex: 'amount', width: 100,
            align: 'center', render: (text, record) => (
                text + '元'
            ), ellipsis: true
        },
        {
            key: 'payState', title: '支付状态', dataIndex: 'payState', width: 80,
            align: 'center', ellipsis: true
        },
        {
            key: 'payAmount', title: '支付金额', dataIndex: 'payAmount', width: 100,
            align: 'center', render: (text, record) => (
                text + '元'
            ), ellipsis: true
        },
        {
            key: 'member', title: '投保会员', dataIndex: 'member', width: 100,
            align: 'center', ellipsis: true
        },
        {
            key: 'supperMember', title: '上级会员', dataIndex: 'supperMember', width: 100,
            align: 'center', ellipsis: true
        },
        {
            key: 'organizationName', title: '所属组织', dataIndex: 'organizationName', width: 100,
            align: 'center', ellipsis: true
        },
        {
            key: 'createBy', title: '创建人', dataIndex: 'createBy', width: 100,
            align: 'center', ellipsis: true
        },
        {
            key: 'createTime', title: '创建时间', dataIndex: 'createTime', width: 100,
            align: 'center', ellipsis: true, render: (text, record) => (
                moment(text).format('YYYY-MM-DD HH:mm:ss')
            )
        },
        {
            key: '1', title: '操作',
            fixed: 'right',
            align: 'center',
            render: (record: any) => (
                <Space size="small">
                    <Button size="small" type="primary" onClick={() => { }}>查看</Button>
                    {record.stateEnum >= 2 && record.insuranceUrl && record.insuranceUrl != '' ? <Button type='dashed' size="small" onClick={() => { onDownLoad(record) }}>下载</Button> : ''}
                </Space>
            ),
        }]

    const onDownLoad = (record: any) => {
        // 生成一个a标签
        let link = document.createElement("a");
        link.style.display = "none";
        link.target = 'none';
        link.href = record.insuranceUrl;
        link.download = record.orderNO;
        document.body.appendChild(link);
        link.click();
    }

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            state: state,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.OnlineOrder.GetOnlineOrderList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    return <div>
        <div>
            <Input.Search allowClear style={{ width: '320px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入保单号、目的地、起运地、投保人、被保人、运输单号" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <Radio.Group defaultValue={"-1"} value={state} buttonStyle="solid"
                onChange={(e) => {
                    setState(e.target.value);
                    setPage({ pageIndex: 1, pageSize: page.pageSize })
                }}>
                <Radio.Button value="-1">全部</Radio.Button>
                <Radio.Button value="0">未支付</Radio.Button>
                <Radio.Button value="1">已支付</Radio.Button>
                <Radio.Button value="2">出单中</Radio.Button>
                <Radio.Button value="3">已出单</Radio.Button>
                <Radio.Button value="4">修改中</Radio.Button>
                <Radio.Button value="5">已修改</Radio.Button>
                <Radio.Button value="6">退保中</Radio.Button>
                <Radio.Button value="7">已退保</Radio.Button>
                <Radio.Button value="8">已完成</Radio.Button>
                <Radio.Button value="9">已失败</Radio.Button>
                <Radio.Button value="10">已取消</Radio.Button>
            </Radio.Group>
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>在线保单列表</span>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
    </div>
}
export default OnlineOrder;
import { Button, Col, Input, Row, Space, Table, Tree, Modal, message, Dropdown, Tooltip, Menu, Divider } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, GlobalOutlined, UnorderedListOutlined, BankOutlined, ClusterOutlined } from '@ant-design/icons';
import UserEdit from "./UserEdit";
import OrganizationEdit from "./OrganizationEdit";
import { useLoaderData } from "react-router-dom";

const { confirm } = Modal;

const OrganizationUser = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [treeData, setTreeData] = useState<any>([]);
    const [expandedKeys, setExpandedKeys] = useState<any>([]);
    const [selectedKeys, setSelectedKeys] = useState<any>([]);
    const [selectedOrganization, setSelectedOrganization] = useState<any>({});
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenUser, setIsOpenUser] = useState(false);
    const [editRecordOrg, setEditRecordOrg] = useState({});
    const [editRecordUser, setEditRecordUser] = useState({});

    useEffect(() => {
        loadData()
    }, [])

    useEffect(() => {
        loadUser();
    }, [selectedKeys, page])

    const loadData = () => {
        var params = {};
        Server.Post(URL.Organization.GetOrganizationList, params).then((response: any) => {
            if (response.isSuccess) {
                var treeData: any[] = [];
                (response.data ?? []).map((item: any, index: any) => {
                    var node = generateData(item);
                    treeData.push(node);
                })
                setTreeData(treeData);
                if (response.data && response.data.length > 0) {
                    setSelectedOrganization({
                        organizationID: response.data[0].organizationID,
                        organizationName: response.data[0].organizationName
                    });
                    setSelectedKeys([response.data[0].organizationID])
                    setExpandedKeys([response.data[0].organizationID]);
                }
            }
        });
    }
    const loadUser = () => {
        var params = {
            organizationID: selectedKeys[0],
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.User.GetUserList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }
    const generateData = (item: any) => {
        var node: any = {
            title: (
                <div style={{ width: '100%', maxWidth: '100%', overflow: 'hidden' }}>
                    <span >
                        <Tooltip placement="topLeft" title={item.organizationName}>
                            {
                                item.organizationTypeEnum == 0 ? <GlobalOutlined /> :
                                    (item.organizationTypeEnum == 1 ? < BankOutlined /> : <ClusterOutlined />)
                            }{item.organizationName}
                        </Tooltip>
                    </span>
                    <span style={{ marginLeft: item.organizationTypeEnum <= 2 ? '30px' : '0px' }} >
                        {
                            <Dropdown overlay={() => {
                                return <Menu >
                                    <Menu.Item key={1} onClick={() => {
                                        setEditRecordOrg({
                                            viewMode: 0,
                                            parentID: item.organizationID,
                                            parentTypeEnum: item.organizationTypeEnum,
                                        })
                                        setIsOpen(true);
                                    }}>{item.organizationTypeEnum == 0 || item.organizationTypeEnum == 1 ? '新增' : '新增子部门'}</Menu.Item>
                                    <Menu.Item key={2} onClick={() => {
                                        setEditRecordOrg({
                                            viewMode: 1,
                                            organizationID: item.organizationID,
                                            parentID: item.organizationID
                                        })
                                        setIsOpen(true);
                                    }}>编辑</Menu.Item>
                                    <Menu.Item key={3} onClick={() => { showDeleteOrgConfirm(item) }}>删除</Menu.Item>
                                </Menu>
                            }}>
                                <a style={{ float: "right" }} onClick={e => e.preventDefault()}>
                                    <UnorderedListOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                                </a>
                            </Dropdown>
                        }
                    </span>
                </div>
            ),
            key: item.organizationID,
            icon: '',
            record: item
        };
        node.children = [];
        (item.children ?? []).map((child: any, index: any) => {
            var childNode = generateData(child);
            node.children.push(childNode);
        })
        return node;
    }
    const showDeleteOrgConfirm = (record: any) => {
        confirm({
            title: '删除组织',
            icon: <ExclamationCircleFilled />,
            content: '组织[' + record.organizationName + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { organizationID: record.organizationID }
                Server.Post(URL.Organization.DeleteOrganization, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('删除成功!');
                        loadData();
                    }
                })
            },
            onCancel() {
            },
        });
    }
    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除用户',
            icon: <ExclamationCircleFilled />,
            content: '用户[' + record.userName + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { userID: record.userID }
                Server.Post(URL.User.DeleteUser, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('删除成功!');
                        loadUser();
                    }
                })
            },
            onCancel() {
            },
        });
    }

    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用用户' : '启用用户',
            icon: <ExclamationCircleFilled />,
            content: '用户[' + record.userName + ']，确认要' + (record.isActive ? '禁用' : '启用'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    userID: record.userID,
                    isActive: !record.isActive
                }
                Server.Post(URL.User.SetActiveUser, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadUser();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const showAdminConfirm = (record: any) => {
        confirm({
            title: record.isAdmin ? '取消管理员' : '设置管理员',
            icon: <ExclamationCircleFilled />,
            content: '用户[' + record.userName + ']，确认要' + (record.isActive ? '取消管理员' : '设置管理员'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    userID: record.userID,
                    isAdmin: !record.isAdmin
                }
                Server.Post(URL.User.SetAdminUser, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isAdmin ? '取消管理员' : '设置管理员') + '成功!');
                        loadUser();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const columns: any[] = [
        {
            title: '用户名称', dataIndex: 'userName', align: 'center', fixed: 'left', width: 100,
            render: (text: any, record: any) => <a onClick={() => {
                setEditRecordUser({
                    viewMode: 1,
                    userID: record.userID,
                    organizationID: record.organizationID,
                    organizationName: record.organizationName,
                });
                setIsOpenUser(true);
            }}>{text}</a>
        },
        { title: '账号', dataIndex: 'account', align: 'center', width: 80 },
        { title: '电话', dataIndex: 'tel', align: 'center', width: 80 },
        { title: '默认组织', dataIndex: 'organizationName', align: 'center', width: 100 },
        {
            title: '激活', dataIndex: 'isActive', align: 'center', width: 80,
            render: (text: any) => (
                text ? '启用' : '禁用'
            ),
        },
        {
            title: '管理员', dataIndex: 'isAdmin', align: 'center', width: 80,
            render: (text: any) => (
                text ? '是' : '否'
            ),
        },
        { title: '备注', dataIndex: 'remark', align: 'center', width: 150 },
        {
            title: '操作', align: 'center', fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    <Button size="small" onClick={() => {
                        setEditRecordUser({
                            viewMode: 1,
                            userID: record.userID,
                            organizationID: record.organizationID,
                            organizationName: record.organizationName,
                        });
                        setIsOpenUser(true);
                    }}>编辑</Button>
                    <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button>
                    {!record.isActive ? <Button size="small" onClick={() => { showActiveConfirm(record) }}>启用</Button> : ''}
                    {record.isActive ? <Button danger size="small" onClick={() => { showActiveConfirm(record) }}>禁用</Button> : ''}
                    {!record.isAdmin ? <Button type='primary' size="small" onClick={() => { showAdminConfirm(record) }}>设置管理员</Button> : ''}
                    {record.isAdmin ? <Button danger size="small" onClick={() => { showAdminConfirm(record) }}>取消管理员</Button> : ''}
                    <Button size="small" type='primary' onClick={() => {
                        window.location.href = '/system/user/auth?userID=' + record.userID;
                    }}>授权</Button></Space>
            ),
        }
    ]

    return <div style={{ height: '100%' }}>
        <Row style={{ height: '100%' }}>
            <Col span={5} >
                <div style={{ border: '1px solid #e7e7eb', height: '100%', overflow: 'auto' }}>
                    <div style={{ backgroundColor: '#fafafa', borderBottom: '1px solid #e7e7eb', height: '35px' }}>
                        <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>组织列表</span>
                    </div>
                    <Tree
                        style={{ margin: '5px 0' }}
                        showLine
                        key={'organizationID'}
                        defaultExpandedKeys={expandedKeys}
                        expandedKeys={expandedKeys}
                        onExpand={(expandedKeys) => {
                            setExpandedKeys(expandedKeys);
                        }}
                        selectedKeys={selectedKeys}
                        onSelect={(selectedKeys, e: any) => {
                            if (selectedKeys.length > 0) {
                                setSelectedOrganization({
                                    organizationID: e.node.record.organizationID,
                                    organizationName: e.node.record.organizationName,
                                    organizationType: e.node.record.organizationType,
                                    organizationTypeEnum: e.node.record.organizationTypeEnum
                                });
                                setSelectedKeys(selectedKeys);
                            }
                        }}
                        treeData={treeData} />
                    <OrganizationEdit isOpen={isOpen} record={editRecordOrg} setIsOpen={setIsOpen} loadData={loadData} />
                </div>
            </Col>
            <Col span={19} >
                <div style={{ paddingLeft: '5px' }}>
                    <div>
                        <Input.Search allowClear style={{ width: '320px' }}
                            onChange={(e) => { setSearchValue(e.target.value) }}
                            onSearch={() => { loadUser() }}
                            placeholder="请输入账号、用户名、电话、备注" />
                    </div>
                    <div style={{ marginTop: '5px' }}>
                        <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                            <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>用户列表</span>
                            <Space style={{ float: 'right' }}>
                                <Button type="primary" disabled={selectedOrganization.organizationTypeEnum > 1} onClick={() => {
                                    setEditRecordUser({
                                        viewMode: 0,
                                        organizationID: selectedOrganization.organizationID,
                                        organizationName: selectedOrganization.organizationName,
                                        userID: null
                                    })
                                    setIsOpenUser((true));
                                }}>新增用户</Button>
                            </Space>
                        </div>
                        <Table size="small"
                            bordered
                            scroll={{ x: 'max-content' }}
                            columns={columns} dataSource={dataSource} pagination={{
                                position: ['bottomRight'],
                                style: { marginRight: '10px' },
                                showSizeChanger: true,
                                showQuickJumper: true,
                                total: total,
                                showTotal: ((total) => {
                                    return "共" + total + "条";
                                }),
                                current: page.pageIndex,
                                defaultCurrent: 20,
                                pageSize: page.pageSize,
                                pageSizeOptions: [10, 20, 50, 100, 200],
                                onShowSizeChange: ((page, pageSize) => {
                                    setPage({
                                        pageIndex: page,
                                        pageSize: pageSize
                                    });
                                }),
                                onChange: ((page, pageSize) => {
                                    setPage({
                                        pageIndex: page,
                                        pageSize: pageSize
                                    });
                                })
                            }} />
                    </div>
                </div>
                <UserEdit isOpen={isOpenUser} setIsOpen={setIsOpenUser} record={editRecordUser} loadData={loadUser} />
            </Col>
        </Row>
    </div>
}
export default OrganizationUser;
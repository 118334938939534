import { Button, Col, DatePicker, Divider, Form, FormInstance, Input, InputNumber, message, Modal, Row, Select, Space, Switch, Table, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';
import CompanyEdit from "../../masterdata/company/CompanyEdit";

const InquiryRecordEdit = (props: any) => {
    const formRef = useRef<FormInstance>(null);
    const { ...rest } = props;
    const [company, setCompany] = useState<any>(null);
    const [companyData, setCompanyData] = useState<any>([]);

    const [isOpenCompany, setIsOpenCompany] = useState(false);
    const [editRecordCompany, setEditRecordCompany] = useState<any>({});

    useEffect(() => {
        if (rest.isOpen) {
            switch (rest.viewMode) {
                case 0:
                    setData(null);
                    break;
                case 1:
                    getInquiryRecord();
                    break;
                case 2:
                    getInquiryRecord();
                    break;
            }
            loadCompany();
        }
    }, [rest.isOpen])

    const loadCompany = () => {
        Server.Post(URL.Company.GetCompanyOptions, {}, false).then((response: any) => {
            if (response.isSuccess) {
                setCompanyData(response.data);
            }
        })
    }

    const setData = (record: any) => {
        if (record) {
            formRef.current?.setFieldsValue({
                ...record,
                insuranceType: record.insuranceType,
                company: { value: record.companyID, label: record.companyName }
            });
            setCompany({ value: record.companyID, label: record.companyName });
        }
        else {
            formRef.current?.resetFields();
            setCompany(null);
        }
    }

    const getInquiryRecord = () => {
        if (rest.inquiryRecordID) {
            var params = {
                inquiryRecordID: rest.inquiryRecordID
            };
            Server.Post(URL.InquiryOrder.GetInquiryRecord, params, false).then((response: any) => {
                if (response.isSuccess) {
                    setData(response);
                }
            })
        }
    }

    const handleCancel = () => {
        rest.setIsOpen(false);
    }

    const handleSave = () => {
        formRef.current?.validateFields().then((values => {
            var params = {
                ...values,
                inquiryRecordID: props.inquiryRecordID ?? null,
                inquiryOrderID: props.inquiryOrderID ?? null,
                inquiryOrderNO: props.inquiryOrderNO ?? '',
                companyID: company.value,
                companyName: company.label,
            };

            Server.Post(URL.InquiryOrder.SaveInquiryRecord, params).then((response: any) => {
                if (response.isSuccess) {
                    message.success('保存成功');
                    rest.setIsOpen(false);
                    rest.loadData();
                } else {
                    message.warn(response.returnMsg);
                }
            })
        })).catch((info: any) => {
            console.log(info);
        });
    }

    useEffect(() => {
        var companyName = company?.label ?? '';
        var inquiryName = formRef.current?.getFieldValue("inquiryName")
        if (!inquiryName || formRef.current?.getFieldValue("inquiryName") == "") {
            var productName = companyName + "";
            formRef.current?.setFieldValue('inquiryName', productName)
        }
    }, [company])

    const filterOption = (input: string, option?: { label: string; value: string }) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    return <Modal title={(rest.viewMode == 0 ? '新增' : '编辑') + "询价记录"} width={800} open={rest.isOpen} onCancel={handleCancel}
        maskClosable={false}
        bodyStyle={{ padding: 0, margin: 8 }}
        footer={
            <Space>
                <Button onClick={handleSave} type='primary'>保存</Button>
                <Button onClick={handleCancel}>关闭</Button>
            </Space>
        }>
        <Form ref={formRef}
            labelCol={{
                xs: { span: 24 },
                sm: { span: 6 }
            }} wrapperCol={{
                xs: { span: 24 },
                sm: { span: 18 }
            }}>
            <Form.Item hidden label={'询价记录ID'} name={'inquiryRecordID'} initialValue={null}>
                <Input readOnly={rest.viewMode == 2} />
            </Form.Item>
            <Row >
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }} label='保险险种' name={'insuranceType'}
                        initialValue={'货运险'}>
                        <Select
                            style={{ width: '100%' }}
                            showSearch
                            optionFilterProp="children"
                            filterOption={filterOption}
                            options={[
                                { value: '货运险', label: '货运险' },
                                { value: '物流责任险', label: '物流责任险' },
                                { value: '雇主责任险', label: '雇主责任险' },
                                { value: '团体意外险', label: '团体意外险' },
                                { value: '仓储险', label: '仓储险' },
                            ]} />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item style={{ marginBottom: '5px' }}
                        label={'保险公司'} name={'company'}
                        initialValue={company} rules={[{ required: true, message: '请选择保险公司' }]}>
                        <Space.Compact style={{ width: '100%' }}>
                            <Select defaultValue={company?.value}
                                value={company?.value}
                                labelInValue
                                // mode={props.viewMode==1?"multiple":'tags'}
                                placeholder={'选择保险公司'}
                                onSelect={(e) => {
                                    setCompany(e);
                                    formRef.current?.setFieldValue('company', e)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                style={{ width: '100%' }}
                                options={companyData} />
                            <Button style={{ width: '50px', marginLeft: '5px' }}
                                onClick={() => {
                                    setEditRecordCompany({
                                        viewMode: 0,
                                        salesID: null
                                    });
                                    setIsOpenCompany(true)
                                }}><PlusOutlined /></Button>
                        </Space.Compact>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 24 }} style={{ marginBottom: '5px' }}
                        label={'询价需求'} name={'required'}
                        initialValue={''} rules={[{ required: true, message: '请输入询价需求' }]}>
                        <Input.TextArea placeholder={'输入询价需求'} rows={5} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item labelCol={{ span: 3 }} wrapperCol={{ span: 24 }} style={{ marginBottom: '5px' }}
                        label={'询价结果'} name={'result'}
                        initialValue={''} rules={[{ message: '请输入询价结果' }]}>
                        <Input.TextArea placeholder={'输入询价结果'} rows={5} />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
        <CompanyEdit isOpen={isOpenCompany} setIsOpen={() => setIsOpenCompany(false)} loadData={loadCompany} {...editRecordCompany} />
    </Modal >
};
export default InquiryRecordEdit;

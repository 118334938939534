import { Button, Input, Space, Table, Modal, message, Tag } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import VendorEdit from "./VendorEdit";
import { text } from "stream/consumers";

const { confirm } = Modal;

const Vendor = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Vendor.GetVendorList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除供应商',
            icon: <ExclamationCircleFilled />,
            content: '供应商编码[' + record.code + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { vendorID: record.vendorID }
                Server.Post(URL.Vendor.DeleteVendor, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const columns: any[] = [
        {
            key: 'code', title: '供应商代码', dataIndex: 'code', fixed: 'left', align: 'center', ellipsis: true, width: 150,
            render: (text: any, record: any) =>
                <a onClick={() => {
                    setEditRecord({
                        viewMode: 1,
                        vendorID: record.vendorID
                    });
                    setIsOpen(true)
                }}>{text}</a>
        }
        , { title: '供应商名称', dataIndex: 'name', align: 'center', ellipsis: true, width: 150 }
        , {
            title: '启用状态', dataIndex: 'isActive', align: 'center', ellipsis: true, width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
            }
        }
        , {
            title: '上线状态', dataIndex: 'isOnline', align: 'center', ellipsis: true, width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">上线</Tag> : <Tag color="red">下线</Tag>
            }
        },
        {
            title: '对接方式', dataIndex: 'connectMode', align: 'center', ellipsis: true, width: 80
        }
        , { title: '联系人', dataIndex: 'contact', align: 'center', ellipsis: true, width: 100 }
        , { title: '电话', dataIndex: 'tel', align: 'center', ellipsis: true, width: 100 }

        , { title: '地址', dataIndex: 'address', align: 'center', ellipsis: true, width: 200 }
        , { title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true, width: 200 }
        , {
            title: '操作', align: 'center', width: 60, ellipsis: true, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    {!record.isOnline ?
                        <>
                            <Button size="small" onClick={() => {
                                setEditRecord({
                                    viewMode: 1,
                                    vendorID: record.vendorID
                                });
                                setIsOpen(true)
                            }}>编辑</Button>
                            <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button>
                        </> : ''}
                    {!record.isActive ? <Button type="primary" size="small" onClick={() => { showActiveConfirm(record) }}>启用</Button> : ''}
                    {record.isActive ? <Button danger size="small" onClick={() => { showActiveConfirm(record) }}>禁用</Button> : ''}
                </Space>
            ),
        }];
    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用供应商' : '启用供应商',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.name + ']，确认要'
                + (record.isActive ? '禁用' : '启用')
                + (record.isActive ? '。禁用供应商同时将下线该供应商已上线及禁用该供应商已启用的所有保险产品,请慎重！' : ''),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    vendorID: record.vendorID,
                    isActive: !record.isActive
                }
                Server.Post(URL.Vendor.SetActiveVendor, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    return <div>
        <div>
            <Input.Search allowClear style={{ width: '320px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入供应商编码、供应商名称、联系人、电话、地址" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>供应商列表</span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            vendorID: null
                        });
                        setIsOpen(true)
                    }}>新增供应商</Button>
                </Space>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
        <VendorEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
    </div>
}
export default Vendor;
import { Button, Input, message, Space, Table, Modal, Tag } from "antd";
import { useEffect, useState } from "react";
import ETableList from "../../../components/eTableList/ETableList";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const OnlineProduct = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);

    const columns: any[] = [
        , { key: 'productName', title: '产品名称', dataIndex: 'productName', align: 'center', fixed: 'left', width: 200 }
        , { key: 'vendorName', title: '供应商', dataIndex: 'vendorName', align: 'center', width: 120 }
        , {
            title: '上线状态', dataIndex: 'isOnline', align: 'center', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">上线</Tag> : <Tag color="red">下线</Tag>;
            }
        }, {
            title: '热门推荐', dataIndex: 'isHot', align: 'center', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">是</Tag> : <Tag color="red">否</Tag>
            }
        }
        , { key: 'companyName', title: '保险公司', dataIndex: 'companyName', align: 'center', width: 120 }
        , { key: 'scope', title: '保险范围', dataIndex: 'scope', align: 'center', width: 80 }
        , { key: 'productType', title: '产品类型', dataIndex: 'productType', align: 'center', width: 80 }
        , { key: 'type', title: '险种类型', dataIndex: 'type', align: 'center', width: 80 }
        , { key: 'rate', title: '费率', dataIndex: 'rate', align: 'center', width: 80 }
        , { key: 'minAmount', title: '底价', dataIndex: 'minAmount', align: 'center', width: 80 }
        , { key: 'limitsAmount', title: '限额', dataIndex: 'limitsAmount', align: 'center', width: 80 }
        , { title: '备注', dataIndex: 'remark', align: 'center', width: 150 }
        , {
            key: 'opration', title: '操作', align: 'center', width: 100, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    {!record.isOnline ? <Button type='primary' onClick={() => { showOnlineConfirm(record) }} size="small">上线</Button> : ''}
                    {record.isOnline ? <Button type='primary' onClick={() => { showOnlineConfirm(record) }} danger size="small">下线</Button> : ''}
                    {!record.isHot ? <Button type='primary' onClick={() => { showHotConfirm(record) }} size="small">设置推荐</Button> : ''}
                    {record.isHot ? <Button type='primary' onClick={() => { showHotConfirm(record) }} danger size="small">取消推荐</Button> : ''}
                </Space>
            )
        }];

    const showOnlineConfirm = (record: any) => {
        confirm({
            title: record.isOnline ? '下线供应商' : '下线供应商',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.productName + ']，确认要' + (record.isOnline ? '下线' : '上线'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    productID: record.productID,
                    isOnline: !record.isOnline
                }
                Server.Post(URL.OnlineProduct.SetOnlineProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isOnline ? '下线' : '上线') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    }
    const showHotConfirm = (record: any) => {
        confirm({
            title: record.isHot ? '取消热门推荐' : '设置热门推荐',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.productName + ']，确认要' + (record.isHot ? '取消' : '设置'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    productID: record.productID,
                    isHot: !record.isHot
                }
                Server.Post(URL.OnlineProduct.SetHotProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isHot ? '取消' : '设置') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    }

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.OnlineProduct.GetProductList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    return <div>
        <div>
            <Input.Search allowClear style={{ width: '320px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入保险范围、保险公司、保险产品" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>线上产品列表</span>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
    </div>
}
export default OnlineProduct;
import { Button, Input, Space, Table, Modal, message, Tag } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from "moment";
import { ExclamationCircleFilled } from '@ant-design/icons';
import AccountRecharge from "./AccountRecharge";
import RechargeRecord from "./RechargeRecord";
import ConsumptionRecord from "./ConsumptionRecord";

const { confirm } = Modal;

const OrganizationAccount = () => {
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [isShowRechargeRecord, setIsShowRechargeRecord] = useState(false);
    const [isShowConsumptionRecord, setIsShowConsumptionRecord] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);

    const columns: any[] = [
        { title: '组织名称', dataIndex: 'organizationName', key: 'organizationName', align: 'center', width: 150 },
        {
            title: '账户状态', dataIndex: 'state', align: 'center', width: 80,
            render: (text: any) => (
                text ? <Tag color="green">已开通</Tag> : <Tag color="red">未开通</Tag>
            ),
        },
        {
            title: '账户余额（元）', dataIndex: 'balance', align: 'center', width: 100,
            render: (text: any, record: any) => (
                record.state ? text : ''
            )
        },
        {
            title: '开户人', dataIndex: 'createBy', align: 'center', width: 150,
            render: (text: any, record: any) => (
                record.state ? text : ''
            )
        },
        {
            title: '开户时间', dataIndex: 'createTime', align: 'center', width: 150,
            render: (text: any, record: any) => (
                record.state ? moment(text).format('YYYY-MM-DD hh:mm:ss') : ''
            )
        },
        {
            title: '操作', align: 'center', width: 50, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    {!record.state ? <Button size="small" type='primary'
                        onClick={() => { showCreateConfirm(record) }}>开户</Button> : ''}
                    {record.state ? <Button size="small" type='primary' danger
                        onClick={() => {
                            setCurrentRecord({
                                accountID: record.accountID,
                                organizationID: record.organizationID,
                                organizationName: record.organizationName
                            })
                            setIsShowRecharge(true);
                        }}>充值</Button> : ''}
                    {record.state ? <Button size="small" type='primary'
                        onClick={() => {
                            setCurrentRecord({
                                accountID: record.accountID,
                                organizationID: record.organizationID,
                                organizationName: record.organizationName
                            })
                            setIsShowRechargeRecord(true);
                        }}>充值记录</Button> : ''}
                    {record.state ? <Button size="small" danger
                        onClick={() => {
                            setCurrentRecord({
                                accountID: record.accountID,
                                organizationID: record.organizationID,
                                organizationName: record.organizationName
                            })
                            setIsShowConsumptionRecord(true);
                        }}>消费记录</Button> : ''}
                </Space>
            ),
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    const loadData = () => {
        var params = {
            searchValue: searchValue
        };
        Server.Post(URL.Account.GetAccountList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                if (response.data && response.data.length > 0) {
                    setExpandedRowKeys([response.data[0].organizationName]);
                }
            }
        });
    }

    const showCreateConfirm = (record: any) => {
        confirm({
            title: '组织开户',
            icon: <ExclamationCircleFilled />,
            content: '组织编码[' + record.organizationName + ']，确认要开户',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { organizationID: record.organizationID }
                Server.Post(URL.Account.CreateAccount, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success('开户成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    return <div>
        <div>
            <Input.Search allowClear style={{ width: '320px', marginRight: '10px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入组织名称" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>组织账户列表</span>
                <Space style={{ float: 'right' }}>
                </Space>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }}
                rowKey={'organizationName'}
                expandedRowKeys={expandedRowKeys}
                onExpand={(expandedKeys: any, record: any) => { setExpandedRowKeys(expandedKeys ? [record.organizationName] : []) }}
                columns={columns} dataSource={dataSource} pagination={false} />
        </div>
        <AccountRecharge isOpen={isShowRecharge} {...currentRecord}
            setIsOpen={(value: any) => setIsShowRecharge(value)} loadData={loadData} />
        <RechargeRecord isOpen={isShowRechargeRecord} {...currentRecord}
            setIsOpen={(value: any) => setIsShowRechargeRecord(value)} />
        <ConsumptionRecord isOpen={isShowConsumptionRecord} {...currentRecord}
            setIsOpen={(value: any) => setIsShowConsumptionRecord(value)} />
    </div>
}
export default OrganizationAccount;
import { Button, Input, Space, Table, Modal, message, Tag } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';
import CustomerEdit from "./CustomerEdit";

const { confirm } = Modal;

const Customer = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Customer.GetCustomerList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除客户资料',
            icon: <ExclamationCircleFilled />,
            content: '客户资料编码[' + record.code + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { customerID: record.customerID }
                Server.Post(URL.Customer.DeleteCustomer, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };

    const columns: any[] = [{
        key: 'code', title: '公司代码', dataIndex: 'code', fixed: 'left', align: 'center', width: 150,
        ellipsis: true,
        render: (text: any, record: any) => <a onClick={() => {
            setEditRecord({
                viewMode: 1,
                customerID: record.customerID
            });
            setIsOpen(true)
        }}>{text}</a>
    }
        , { title: '公司名称', dataIndex: 'name', align: 'center', ellipsis: true, width: 150 }
        , {
        title: '启用状态', dataIndex: 'isActive', align: 'center', ellipsis: true, width: 100,
        render: (text: any) => {
            return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
        }
    }
        , { title: '联系人', dataIndex: 'contact', align: 'center', ellipsis: true, width: 150 }
        , { title: '电话', dataIndex: 'tel', align: 'center', ellipsis: true, width: 150 }
        , { title: '地址', dataIndex: 'address', align: 'center', ellipsis: true, width: 150 }
        , { title: '备注', dataIndex: 'remark', align: 'center', ellipsis: true, width: 150 }
        , {
        title: '操作', align: 'center', width: 60, fixed: 'right', ellipsis: true, render: (record: any) => (
            <Space size="small">
                <Button size="small" onClick={() => {
                    setEditRecord({
                        viewMode: 1,
                        customerID: record.customerID
                    });
                    setIsOpen(true)
                }}>编辑</Button>
                <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button>
                {!record.isActive ? <Button size="small" onClick={() => { showActiveConfirm(record) }}>启用</Button> : ''}
                {record.isActive ? <Button danger size="small" onClick={() => { showActiveConfirm(record) }}>禁用</Button> : ''}
            </Space>
        ),
    }];
    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用客户资料' : '启用客户资料',
            icon: <ExclamationCircleFilled />,
            content: '客户资料[' + record.name + ']，确认要' + (record.isActive ? '禁用' : '启用'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    customerID: record.customerID,
                    isActive: !record.isActive
                }
                Server.Post(URL.Customer.SetActiveCustomer, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    return <div>
        <div>
            <Input.Search allowClear style={{ width: '320px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入公司编码、公司名称、联系人、电话、地址" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>客户资料列表</span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => {
                        setEditRecord({
                            viewMode: 0,
                            customerID: null
                        });
                        setIsOpen(true)
                    }}>新增客户</Button>
                </Space>
            </div>
            <Table size="small"
                bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { marginRight: '10px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
        </div>
        <CustomerEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
    </div>
}
export default Customer;
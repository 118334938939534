import { Button, Input, message, Space, Table, Modal, Tag, Dropdown, Typography } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import moment from "moment";
import ApplyAllocationEdit from "./ApplyAllocationEdit";
import RechargeApplyAllocation from "./RechargeApplyAllocation";
const { confirm } = Modal;

const ApplyAllocation = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});
    const [isShowRecharge, setIsShowRecharge] = useState(false);
    const [record, setRecord] = useState<any>({});

    const columns: any[] = [
        {
            key: 'applyAllocationNO', title: '保单编码', dataIndex: 'applyAllocationNO', align: 'center'
            , fixed: 'left', ellipsis: true, width: 100,
            render: (text: string, record: any) => <a onClick={() => {
                window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID
            }}>{text}</a>
        }, {
            key: 'companyName', title: '保险公司', dataIndex: 'companyName', align: 'center'
            , ellipsis: true, width: 100
        }, {
            key: 'applyProductName', title: '申报产品', dataIndex: 'applyProductName', align: 'center'
            , ellipsis: true, width: 100
        }, {
            key: 'member', title: '会员', dataIndex: 'memberName', align: 'center'
            , ellipsis: true, width: 100
        }, {
            key: 'agreementNO', title: '协议号', dataIndex: 'agreementNO', align: 'center'
            , ellipsis: true, width: 100
        }
        , { key: 'insureBy', title: '投保人', dataIndex: 'insureBy', align: 'center', ellipsis: true, width: 220 }
        , { key: 'insuredBy', title: '被保人', dataIndex: 'insuredBy', align: 'center', ellipsis: true, width: 220 }
        , {
            key: 'amount', title: '累计金额', dataIndex: 'amount', align: 'center', width: 120, ellipsis: true,
            render: (text: string) => {
                return text + '万元';
            }
        }
        , {
            key: 'amount', title: '保单余额', dataIndex: 'usedAmount', align: 'center', width: 120, ellipsis: true,
            render: (text: string, record: any) => {
                return (record.amount - record.usedAmount) + '万元';
            }
        }
        , {
            key: 'effectTime', title: '生效时间', dataIndex: 'effectTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                moment(text).format('YYYY-MM-DD')
            )
        }, {
            key: 'expireTime', title: '失效时间', dataIndex: 'expireTime',
            align: 'center', width: 100, ellipsis: true, render: (text: string, record: any) => (
                <span style={{ color: (moment(text) < moment()) ? 'red' : 'green' }}>{moment(text).format('YYYY-MM-DD')}</span>
            )
        },
        , { key: 'organizationName', title: '所属公司', dataIndex: 'organizationName', align: 'center', width: 120 }
        , { key: 'salesName', title: '业务员', dataIndex: 'salesName', align: 'center', width: 120 }
        , { title: '备注', dataIndex: 'remark', align: 'center', width: 150, ellipsis: true }
        , {
            key: 'opration', title: '操作', align: 'center', width: 120, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    <Button type='primary' onClick={() => { window.location.href = '/yearonline/applyAllocationDetail?oid=' + record.applyAllocationID }} size="small">查看</Button>
                    {moment(record.expireTime) > moment() ? <Button type='primary' danger onClick={() => {
                        setRecord({
                            applyAllocationID: record.applyAllocationID,
                        })
                        setIsShowRecharge(true);
                    }} size="small">充值</Button> : <></>}
                </Space >
            )
        }];

    useEffect(() => {
        loadData();
    }, [])

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.ApplyAllocation.GetApplyAllocationList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }

    return <div style={{ height: '100%' }}>
        <div>
            <div>
                <Input.Search allowClear style={{ width: '320px' }}
                    onChange={(e) => { setSearchValue(e.target.value) }}
                    onSearch={() => { loadData() }}
                    placeholder="输入保险公司、申报产品、会员、协议号、投保人、被保人、保险区域模糊检索" />
            </div>
            <div style={{ marginTop: '5px' }}>
                <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                    <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>保单管理列表</span>
                    <Space style={{ float: 'right' }}>
                        <Button type="primary" onClick={() => {
                            setEditRecord({
                                viewMode: 0,
                                vendorID: null
                            });
                            setIsOpen(true)
                        }}>签约保单</Button>
                    </Space>
                </div>
                <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns}
                    dataSource={dataSource}
                    summary={(pageData) => {
                        var totalAmount = 0;
                        var totalUsedAmount = 0;
                        pageData.forEach(({ amount, usedAmount }) => {
                            totalAmount += amount;
                            totalUsedAmount += usedAmount;
                        });
                        return <>
                            <Table.Summary.Row>
                                <Table.Summary.Cell index={0} colSpan={1} align={'center'}>
                                    <Typography.Text>合计</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={1} colSpan={6} align={'center'}>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={2} colSpan={1} align={'center'}>
                                    <Typography.Text>{totalAmount}{'万元'}</Typography.Text>
                                </Table.Summary.Cell>
                                <Table.Summary.Cell index={3} colSpan={1} align={'center'}>
                                    <Typography.Text>{totalUsedAmount}{'万元'}</Typography.Text>
                                </Table.Summary.Cell>
                            </Table.Summary.Row>
                        </>
                    }}
                    pagination={{
                        position: ['bottomRight'],
                        style: { marginRight: '10px' },
                        showSizeChanger: true,
                        showQuickJumper: true,
                        total: total,
                        showTotal: ((total) => {
                            return "共" + total + "条";
                        }),
                        current: page.pageIndex,
                        defaultCurrent: 20,
                        pageSize: page.pageSize,
                        pageSizeOptions: [10, 20, 50, 100, 200],
                        onShowSizeChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        }),
                        onChange: ((page, pageSize) => {
                            setPage({
                                pageIndex: page,
                                pageSize: pageSize
                            });
                        })
                    }} />
            </div>
        </div>
        <ApplyAllocationEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
        <RechargeApplyAllocation isOpen={isShowRecharge} {...record} loadData={loadData} setIsOpen={(value: any) => setIsShowRecharge(value)} />
    </div>
}
export default ApplyAllocation;
import { Button, Input, message, Space, Table, Modal, Tag, Dropdown } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled, DownOutlined } from '@ant-design/icons';
import ProductEdit from "./SingleProductEdit";
import ProductAddition from "./SingleProductAddition";
const { confirm } = Modal;

const Product = () => {
    const [searchValue, setSearchValue] = useState('');
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenAddition, setIsOpenAddition] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    const columns: any[] = [
        {
            key: 'productName', title: '产品名称', dataIndex: 'productName', align: 'center'
            , fixed: 'left', ellipsis: true, width: 220,
            render: (text: any, record: any) => <a onClick={() => {
                setEditRecord({
                    viewMode: 1,
                    productID: record.productID
                });
                setIsOpen(true)
            }}>{text}</a>
        }
        , { key: 'vendorName', title: '供应商', dataIndex: 'vendorName', align: 'center', width: 120 }
        , {
            title: '启用状态', dataIndex: 'isActive', align: 'center', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
            }
        }
        , {
            title: '上线状态', dataIndex: 'isOnline', align: 'center', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">上线</Tag> : <Tag color="red">下线</Tag>
            }
        }, {
            title: '热门推荐', dataIndex: 'isHot', align: 'center', width: 80,
            render: (text: any) => {
                return text ? <Tag color="green">是</Tag> : <Tag color="red">否</Tag>
            }
        }
        , { key: 'companyName', title: '保险公司', dataIndex: 'companyName', align: 'center', width: 120 }
        , { key: 'scope', title: '保险范围', dataIndex: 'scope', align: 'center', width: 100 }
        , { key: 'productType', title: '产品类型', dataIndex: 'productType', align: 'center', width: 100 }
        , { key: 'type', title: '险种类型', dataIndex: 'type', align: 'center', width: 100 }
        , { key: 'rate', title: '费率', dataIndex: 'rate', align: 'center', width: 100 }
        , { key: 'minAmount', title: '底价', dataIndex: 'minAmount', align: 'center', width: 100 }
        , { key: 'limitsAmount', title: '限额', dataIndex: 'limitsAmount', align: 'center', width: 80 }
        , { key: 'vendorProductID', title: '供应商产品ID', dataIndex: 'vendorProductID', align: 'center', width: 80 }
        , { title: '备注', dataIndex: 'remark', align: 'center', width: 150 }
        , {
            key: 'opration', title: '操作', align: 'center', width: 150, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    {!record.isOnline ?
                        <>
                            <Button size="small" onClick={() => {
                                setEditRecord({
                                    viewMode: 1,
                                    productID: record.productID
                                });
                                setIsOpen(true)
                            }}>编辑</Button>
                            <Button type='primary' onClick={() => { showDeleteConfirm(record) }} danger size="small">删除</Button>
                        </> : ''}
                    {!record.isActive ? <Button type='primary' onClick={() => { showActiveConfirm(record) }} size="small">启用</Button> : ''}
                    {record.isActive ? <Button type='primary' onClick={() => { showActiveConfirm(record) }} size="small" danger>禁用</Button> : ''}
                    <>
                        <Dropdown menu={{
                            items, onClick: (e) => {
                                onclickRow(record, e)
                            }
                        }}>
                            <Button type="primary" size="small">
                                更多<DownOutlined />
                            </Button>
                        </Dropdown>
                    </>
                </Space >
            )
        }];

    const items = [
        { key: 0, label: '投保申明' },
        { key: 1, label: '投保须知' },
        { key: 2, label: '保险条款' },
    ];

    const onclickRow = (record: any, item: any) => {
        var type = 0;
        var title = '投保申明'
        switch (item.key) {
            case '0':
                type = 0;
                title = '投保申明'
                break;
            case '1':
                type = 1;
                title = '投保须知'
                break;
            case '2':
                type = 2;
                title = '保险条款'
                break;
        }
        setEditRecord({
            type: type,
            title: title + '[' + record.productName + ']',
            productID: record.productID
        })
        setIsOpenAddition(true)
    };

    const showDeleteConfirm = (record: any) => {
        confirm({
            title: '删除产品',
            icon: <ExclamationCircleFilled />,
            content: '产品名称[' + record.productName + ']，确认要删除，一旦删除将不能恢复，请慎重操作',
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = { productID: record.productID }
                Server.Post(URL.Product.DeleteProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        loadData();
                        message.success('删除成功!');
                    }
                })
            },
            onCancel() {
            },
        });
    };
    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用保险产品' : '启用保险产品',
            icon: <ExclamationCircleFilled />,
            content: '保险产品[' + record.productName + ']，确认要'
                + (record.isActive ? '禁用' : '启用')
                + (record.isActive ? '。禁用保险产品同时将下线已上线的保险产品,请慎重！' : ''),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    productID: record.productID,
                    isActive: !record.isActive
                }
                Server.Post(URL.Product.SetActiveProduct, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    useEffect(() => {
        loadData();
    }, [])

    const loadData = () => {
        var params = {};
        Server.Post(URL.Product.GetProductList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }

    return <div style={{ height: '100%' }}>
        <div>
            <div>
                <Input.Search allowClear style={{ width: '320px' }}
                    onChange={(e) => { setSearchValue(e.target.value) }}
                    onSearch={() => { loadData() }}
                    placeholder="输入保险范围、保险产品" />
            </div>
            <div style={{ marginTop: '5px' }}>
                <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                    <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>保险产品列表</span>
                    <Space style={{ float: 'right' }}>
                        <Button type="primary" onClick={() => {
                            setEditRecord({
                                viewMode: 0,
                                vendorID: null
                            });
                            setIsOpen(true)
                        }}>新增保险产品</Button>
                    </Space>
                </div>
                <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                    position: ['bottomRight'],
                    style: { marginRight: '10px' },
                    showSizeChanger: true,
                    showQuickJumper: true,
                    total: total,
                    showTotal: ((total) => {
                        return "共" + total + "条";
                    }),
                    current: page.pageIndex,
                    defaultCurrent: 20,
                    pageSize: page.pageSize,
                    pageSizeOptions: [10, 20, 50, 100, 200],
                    onShowSizeChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    }),
                    onChange: ((page, pageSize) => {
                        setPage({
                            pageIndex: page,
                            pageSize: pageSize
                        });
                    })
                }} />
            </div>
        </div>
        <ProductEdit isOpen={isOpen} setIsOpen={() => setIsOpen(false)} loadData={loadData} {...editRecord} />
        <ProductAddition isOpen={isOpenAddition} setIsOpen={() => setIsOpenAddition(false)} {...editRecord} />
    </div>
}
export default Product;
import { Button, Divider, Input, InputNumber, message, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";

const AgentRateItem = (props: any) => {
    const { ...rest } = props;
    const [dataSource, setDataSource] = useState<Array<any>>([]);
    const [dataRowSpan, setDataRowSpan] = useState<Array<any>>([]);

    const onCell = (data: any, index: number | undefined) => {
        return {
            rowSpan: filterRow(data, index)
        }
    }

    const columns: any[] = [
        {
            key: 'productName', title: '保险产品', dataIndex: 'productName', align: 'center'
            , onCell: () => onCell,
        },
        { key: 'productRate', title: '标准费率（万分比）', dataIndex: 'productRate', align: 'center', width: 150 },
        { key: 'productMinAmount', title: '最低收费（元）', dataIndex: 'productMinAmount', align: 'center', width: 150 },
        {
            key: 'rate', title: '代理费率（万分比）', dataIndex: 'rate', align: 'center',
            width: 150
            , render: (text: any, record: any, index: number) => {
                return <InputNumber style={{ width: '100%' }} status={record.rate != record.productRate ? 'error' : ''} min={record.productRate} value={record.rate} step="0.01" onChange={(e: any) => {
                    record.rate = (e == null || e < record.productRate) ? record.productRate : e;
                    onChange(record, index);
                }} />;
            },
        },
        {
            key: 'minAmount', title: '代理最低收费（元）', dataIndex: 'minAmount', align: 'center',
            width: 150,
            render: (text: any, record: any, index: number) => {
                return <InputNumber style={{ width: '100%' }} status={record.minAmount != record.productMinAmount ? 'error' : ''} min={record.productMinAmount} value={record.minAmount} step="1" onChange={(e: any) => {
                    record.minAmount = (e == null || e < record.productMinAmount) ? record.productMinAmount : e;
                    onChange(record, index);
                }} />;
            },
        }]

    const onChange = (row: any, index: number) => {
        const newData = [...dataSource];
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row,
        });
        setDataSource(newData);
    }

    const filterRow = (data: any, index: number | undefined) => {
        var filter = dataRowSpan.filter(d => d.name == data.productName);
        if (filter && filter.length > 0) {
            return filter[0].index == index ? filter[0].rowSpan : 0;
        }
        return 1;
    }

    useEffect(() => {
        var params = {
            memberID: rest.member.memberID,
            member: rest.member.account,
            companyID: rest.curentCompany.id,
            companyName: rest.curentCompany.name
        }
        Server.Post(URL.Agent.GetAgentRateList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
            }
        });
    }, [rest.curentCompany])

    useEffect(() => {
        setDataRowSpan([]);
        (dataSource??[]).map((item: any, index: number) => {
            var filter = dataRowSpan.filter(d => d.name == item.productName);
            if (filter && filter.length == 0) {
                var filterData = dataSource.filter(d => d.productName == item.productName);
                dataRowSpan.push({
                    name: item.productName,
                    index: index,
                    rowSpan: filterData.length
                })
            }
        })
    }, [dataSource])

    const saveRate = () => {
        var params = {
            memberID: rest.member.memberID,
            companyID: rest.curentCompany.id,
            data: dataSource
        }
        Server.Post(URL.Agent.SaveAgentRate, params).then((response: any) => {
            if (response.isSuccess) {
                message.success('保险公司：' + rest.curentCompany.name + '，代理费率保存成功');
            }
        });
    }

    // const components = {
    //     body: {
    //       row: EditableRow,
    //       cell: EditableCell,
    //     },
    //   };

    return <div style={{ margin: '5px', padding: '5px' }}>
        <Divider>{rest.curentCompany.name}</Divider>
        <div>
            <Table
                //  components={components}
                pagination={false} size='small' bordered columns={columns} dataSource={dataSource}></Table>
            <Button type='primary' style={{ float: 'right', marginTop: '5px' }} onClick={saveRate}>保存</Button>
        </div>
    </div>
}
export default AgentRateItem;
import { Button, Input, Space, Table, Modal, message, Tag } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import { ExclamationCircleFilled } from '@ant-design/icons';

const { confirm } = Modal;

const OnlineVendor = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [editRecord, setEditRecord] = useState<any>({});

    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.OnlineVendor.GetVendorList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        })
    }

    const columns: any[] = [{
        key: 'code', title: '供应商代码', dataIndex: 'code', fixed: 'left', align: 'center', width: 150,
        render: (text: any, record: any) => <a onClick={() => {
            setEditRecord({
                viewMode: 1,
                vendorID: record.vendorID
            });
            setIsOpen(true)
        }}>{text}</a>
    }
        , { title: '供应商名称', dataIndex: 'name', align: 'center', width: 150 }
        , {
        title: '上线状态', dataIndex: 'isOnline', align: 'center', width: 80,
        render: (text: any) => {
            return text ? <Tag color="green">上线</Tag> : <Tag color="red">下线</Tag>;
        }
    }
        , { title: '联系人', dataIndex: 'contact', align: 'center', width: 150 }
        , { title: '电话', dataIndex: 'tel', align: 'center', width: 150 }

        , { title: '地址', dataIndex: 'address', align: 'center', width: 150 }
        , { title: '备注', dataIndex: 'remark', align: 'center', width: 150 }
        , {
        title: '操作', align: 'center', width: 60, fixed: 'right',
        render: (record: any) => (
            <Space size="small">
                {!record.isOnline ? <Button type='primary' size="small" onClick={() => { showOnlineConfirm(record) }}>上线</Button> : ''}
                {record.isOnline ? <Button type='primary' danger size="small" onClick={() => { showOnlineConfirm(record) }}>下线</Button> : ''}
            </Space>
        ),
    }];
    const showOnlineConfirm = (record: any) => {
        confirm({
            title: record.isOnline ? '下线供应商' : '下线供应商',
            icon: <ExclamationCircleFilled />,
            content: '供应商[' + record.name + ']，确认要' + (record.isOnline ? '下线' : '上线'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    vendorID: record.vendorID,
                    isOnline: !record.isOnline
                }
                Server.Post(URL.OnlineVendor.SetOnlineVendor, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isOnline ? '下线' : '上线') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };

    return <div>
        <div >
            <Input.Search allowClear style={{ width: '320px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入供应商编码、供应商名称、联系人、电话、地址" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>供应商列表</span>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
    </div>
}
export default OnlineVendor;
import { Button, Input, Space, Table, Modal, message, Tag } from "antd";
import { useEffect, useState } from "react";
import Server from "../../../server/Server";
import URL from "../../../server/URL";
import moment from 'moment';
import 'moment/locale/zh-cn';
import { ExclamationCircleFilled } from '@ant-design/icons';
import OnlineUserEdit from "./OnlineUserEdit";

const { confirm } = Modal;

const OnlineUser = () => {
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState<any>({ pageIndex: 1, pageSize: 10 });
    const [searchValue, setSearchValue] = useState('');
    const [dataSource, setDataSource] = useState<any>([]);
    const [isOpen, setIsOpen] = useState(false);

    const columns: any[] = [
        {
            title: '会员账号', dataIndex: 'account', fixed: 'left', align: 'center', width: 150
        }
        , { title: '会员名称', dataIndex: 'name', fixed: 'left', align: 'center', width: 150 }
        , {
            title: '启用状态', dataIndex: 'isActive', align: 'center', width: 100,
            render: (text: any) => {
                return text ? <Tag color="green">启用</Tag> : <Tag color="red">禁用</Tag>
            }
        }
        , { title: '手机号', dataIndex: 'tel', align: 'center', width: 150 }
        , { title: '会员级别', dataIndex: 'memberType', align: 'center', width: 150 }
        , {
            title: '注册时间', dataIndex: 'createTime', align: 'center', width: 150
            , render: (text: any) => (
                moment(text).format('YYYY-MM-DD hh:mm:ss')
            )
        }
        , { title: '上级代理', dataIndex: 'superiorMember', align: 'center', width: 150 }
        , { title: '所属组织', dataIndex: 'organizationName', align: 'center', width: 150 }
        , {
            title: '操作', align: 'center', width: 50, fixed: 'right',
            render: (record: any) => (
                <Space size="small">
                    {!record.isActive ? <Button type="primary" size="small" onClick={() => { showActiveConfirm(record) }}>启用</Button> : ''}
                    {record.isActive ? <Button size="small" danger type='primary' onClick={() => { showActiveConfirm(record) }}>禁用</Button> : ''}
                </Space>
            )
        }];

    useEffect(() => {
        loadData();
    }, [])
    useEffect(() => {
        loadData();
    }, [page])

    const loadData = () => {
        var params = {
            searchValue: searchValue,
            pageIndex: page.pageIndex,
            pageSize: page.pageSize
        };
        Server.Post(URL.Member.GetMemberList, params).then((response: any) => {
            if (response.isSuccess) {
                setDataSource(response.data);
                setTotal(response.total);
            }
        });
    }

    const showActiveConfirm = (record: any) => {
        confirm({
            title: record.isActive ? '禁用会员' : '启用会员',
            icon: <ExclamationCircleFilled />,
            content: '会员[' + record.account + ']，确认要' + (record.isActive ? '禁用' : '启用'),
            okText: '确定',
            cancelText: '取消',
            onOk() {
                var param = {
                    memberID: record.memberID,
                    isActive: !record.isActive
                }
                Server.Post(URL.Member.SetActiveMember, param).then((response: any) => {
                    if (response.isSuccess) {
                        message.success((record.isActive ? '禁用' : '启用') + '成功!');
                        loadData();
                    } else {
                        message.warn(response.returnMsg);
                    }
                })
            },
            onCancel() {
            },
        });
    };


    return <div>
        <div>
            <Input.Search allowClear style={{ width: '320px' }}
                onChange={(e) => { setSearchValue(e.target.value) }}
                onSearch={() => { loadData() }}
                placeholder="请输入会员编号、手机号、上级代理" />
        </div>
        <div style={{ marginTop: '5px' }}>
            <div style={{ backgroundColor: '#fafafa', border: '1px solid #e7e7eb', borderBottom: 'none', height: '45px', padding: '5px' }}>
                <span style={{ lineHeight: '30px', margin: '5px', fontWeight: 'bold' }}>在线会员列表</span>
                <Space style={{ float: 'right' }}>
                    <Button type="primary" onClick={() => setIsOpen(true)}>开通会员</Button>
                </Space>
            </div>
            <Table size="small" bordered scroll={{ x: 'max-content' }} columns={columns} dataSource={dataSource} pagination={{
                position: ['bottomRight'],
                style: { marginRight: '10px' },
                showSizeChanger: true,
                showQuickJumper: true,
                total: total,
                showTotal: ((total) => {
                    return "共" + total + "条";
                }),
                current: page.pageIndex,
                defaultCurrent: 20,
                pageSize: page.pageSize,
                pageSizeOptions: [10, 20, 50, 100, 200],
                onShowSizeChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                }),
                onChange: ((page, pageSize) => {
                    setPage({
                        pageIndex: page,
                        pageSize: pageSize
                    });
                })
            }} />
        </div>
        <OnlineUserEdit isOpen={isOpen} setIsOpen={setIsOpen} loadData={loadData} />
    </div>
}
export default OnlineUser;